import { VT_API, BLOCKPAY_API, API } from "../instances"

export const auth = async (payload) => {
  const result = await API()
    .post("user/authentication", payload)
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result
  }
  return []
}

export const getToken = async () => {
  const result = await VT_API()
    .post("Token", {
      username: "a.gomez@visiontravel.net",
      password: "4n4M4r14.321"
    })
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result.data
  }
  return []
}

export const getTokenBP = async () => {
    const response = await BLOCKPAY_API().get(`authenticate/token`)
    return response.data.data
}
