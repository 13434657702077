/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const GenericContext = createContext();

export const GenericProvider = ({ children }) => {
  const i18nextLng = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : undefined

  const [language, setLanguage] = useState(i18nextLng)

  const [activeTab, setActiveTab] = useState('1')

  const toggleTab = (tab) => {
      setActiveTab(tab)
  }

  return (
    <GenericContext.Provider
      value={{
        activeTab, setActiveTab,
        toggleTab,
        language, setLanguage
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};

export const genericContext = () => {
  return useContext(GenericContext);
};
