/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const useLocal = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : undefined;
  const userEmployeeLocal = localStorage.getItem("userEmployee")
    ? JSON.parse(localStorage.getItem("userEmployee"))
    : undefined;
  const tokenLocal = localStorage.getItem("tokenBP")
    ? localStorage.getItem("tokenBP")
    : undefined;

  const [user, setUser] = useState(useLocal);
  const [userEmployee, setUserEmployee] = useState(userEmployeeLocal);
  const [token, setToken] = useState(tokenLocal);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userEmployee, 
        setUserEmployee,
        token, setToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const userContext = () => {
  return useContext(UserContext);
};
