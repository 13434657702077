import { MEMBERSHIP_API, API } from "../instances"

export const get = async (userId) => {
    const response = await API().get(`user/user/getById/${userId}`)
    return response.data.data
}

export const update = async (data) => {
    const response = await API().put(`user/user/update`, data)
    return response.data.data
}

export const updateIBOS = async (data) => {
    const response = await MEMBERSHIP_API().post(`contact/update`, data)
    return response.data.data
}