/* eslint-disable quote-props */
import axios from "axios"
import { APPLICATION_ID } from "../../utility/constants"
import { jwtDecode }  from 'jwt-decode'

export const OV_API = () => {
  const tokenVT = localStorage.getItem('tokenVT')

  const instance = axios.create({
    baseURL: process.env.REACT_APP_OV_API_URL,
    headers: {
      "accept-language": "es",
      "authorization": `bearer ${tokenVT}`
    },
    validateStatus(status) {
      if (status === 401) {
        localStorage.removeItem('tokenVT')
        localStorage.removeItem('userInfo')
        window.location.href = "/login"
      } else {
        return true
      }
    }
  })
  return instance
}

export const VT_API = () => {
  const tokenVT = localStorage.getItem('tokenVT')
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_VT_URL,
    headers: {
      "accept-language": "es",
      "authorization": `bearer ${tokenVT}`
    },
    validateStatus(status) {
      if (status === 401) {
        localStorage.removeItem('tokenVT')
        localStorage.removeItem('userInfo')
        // window.location.href = "/login"
      } else {
        return true
      }
    }
  })
  return instance
}


export const BLOCKPAY_API = (token = undefined) => {
  const tokenBP = token ? token : localStorage.getItem('tokenBP')

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BLOCKPAY_URL,
    headers: {
      "accept-language": "es",
      "authorization": `bearer ${tokenBP}`
    }
  })
  return instance
}

export const ACCOUNTING_API = () => {
  const tokenVT = localStorage.getItem('tokenVT')

  const instance = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNTING_URL,
    headers: {
      "accept-language": "es",
      "authorization": `bearer ${tokenVT}`
    },
    validateStatus(status) {
      if (status === 401) {
        localStorage.removeItem('tokenVT')
        localStorage.removeItem('userInfo')
        window.location.href = "/login"
      } else {
        return true
      }
    }
  })
  return instance
}

export const MEMBERSHIP_API = () => {

  const instance = axios.create({
    baseURL: process.env.REACT_APP_MEMBERSHIP_URL,
    headers: {
      "accept-language": "es"
    }
  })
  return instance
}

export const API = (data) => {

  const token = localStorage.getItem('tokenBP')
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Accept-Language': localStorage.getItem('i18nextLng'),
      Authorization: `Bearer ${token}`,
      'device-id': localStorage.getItem('__mmapiwsid'),
      'application-id': APPLICATION_ID   }
  })

  instance.interceptors.request.use(
    async config => {
      if (token) {
        const decode = jwtDecode(token)
        const now = Math.floor(Date.now() / 1000)
        if (decode?.exp && decode.exp < now) {
          const response = await axios
            .create({
              baseURL: process.env.REACT_APP_API_URL,
              headers: {
                'Accept-Language': localStorage.getItem('i18nextLng'),
                Authorization: `Bearer ${token}`,
                'device-id': localStorage.getItem('__mmapiwsid'),
                'application-id': APPLICATION_ID
              }
            })
            .get('user/token/refresh')
          const newToken = response?.data?.data?.newToken
          localStorage.setItem('tokenBP', newToken)
          if (data?.setToken) {
            data?.setToken(newToken)
          }
          config.headers.Authorization = `Bearer ${newToken}`
        }
      }

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    async response => {
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )

  return instance
}

