/*eslint-disable*/
import { Home, DollarSign, List, Send, Box, Info, Shield, Circle } from "react-feather";
import { useTranslation } from "react-i18next";
import { userContext } from "../../context/user";
import { USER_TYPES } from "../../resources/enums";
import useTwoFactorAuth from "../../hooks/user/useTwoFactorAuth";

const Menu = () => {
	const { user, userEmployee } = userContext();
	const { enableTFA } = useTwoFactorAuth()
	const { t } = useTranslation();

	const menuItems =
		(user?.userTypeId === USER_TYPES.IBO )  
			? [
				{
					id: "home",
					title: t("Home"),
					icon: <Home size={20} />,
					navLink: "/home",
				},
				{
					id: "transactions",
					title: t("Transactions"),
					icon: <List size={20} />,
					navLink: "/transactions",
				},
				{
					id: "transfer",
					title: t("Transfer"),
					icon: <Send size={20} />,
					navLink: "/transfer",
				},
				{
					id: "block-invest",
					title: t("Block Invest"),
					icon: <Box size={20} />,
					navLink: "/block-invest",
				},
				{
					id: "invest",
					title: t("Investments"),
					icon: <DollarSign size={20} />,
					children: [
						{
							id: "resume",
							title: t("Resume"),
							icon: <Circle />,
							navLink: "/investment/summary"
						},
						{
							id: "detail",
							title: t("Detail"),
							icon: <Circle />,
							navLink: "/investment/detail"
						}
					]
				},
				{
					id: "faq",
					title: t("FAQ"),
					icon: <Info size={20} />,
					navLink: "/faq",
				},
				{
					header: t("Terms")
				},
				{
					id: 'disclaimer',
					title: t("Disclaimer"),
					icon: <Shield />,
					navLink: "/disclaimer",
				},
				{
					id: 'privacity',
					title: t("Privacity"),
					icon: <Shield />,
					navLink: "/privacy",
				}
			]
			: [
				{
					id: "home",
					title: t("Home"),
					icon: <Home size={20} />,
					navLink: "/home",
				},
				{
					id: "transactions",
					title: t("Transactions"),
					icon: <List size={20} />,
					navLink: "/transactions",
				},
				{
					id: "transfer",
					title: t("Transfer"),
					icon: <Send size={20} />,
					navLink: "/transfer",
				},
				{
					header: t("Terms")
				},
				{
					id: 'disclaimer',
					title: t("Disclaimer"),
					icon: <Shield />,
					navLink: "/disclaimer",
				},
				{
					id: 'privacity',
					title: t("Privacity"),
					icon: <Shield />,
					navLink: "/privacy",
				}
			];

	return {
		menuItems: enableTFA || userEmployee !== undefined  ? menuItems : [],
	};
};

export default Menu;
