/*eslint-disable*/
import { useEffect, useState } from "react"
import Notification from "../../@core/components/notifications"
import { useTranslation } from "react-i18next"
import { generateOTP, validateOTP, verifyOTP, disableOTP } from "../../api/2fa"
import { userContext } from "../../context/user"
import { get } from "../../api/contact"

const useTwoFactorAuth = () => {
    const { user } = userContext()
	const { t } = useTranslation()
	const { customNotification } = Notification()
    const [enableTFA, setEnableTFA] = useState()

    const [showDetailModal, setShowDetailModal] = useState(false)
    const [token, setToken] = useState("")
	const [secret, setSecret] = useState({
        otpauth_url: "",
        base32: "",
      })

    useEffect(() => {
      async function getContact(){
        if(user) {
            const contact = await get(user?.userId)
            setEnableTFA(contact.enabledOtp)
        }
      }
      getContact()
    }, [user])
    

	const enabledTFA = async () => {
		const response = await generateOTP({ userId: user.userId })
		if (response?.data?.success) {
            setSecret({
                base32: response.data.data[0].base32,
                otpauth_url: response.data.data[0].otpUrl,
            });		
		} else {
			customNotification(t("Error"), t("VerifyTFAError"), "danger")
		}
	}

    const verifyTFA = async (values) => {
		const response = await verifyOTP({
            token: `${values.n1}${values.n2}${values.n3}${values.n4}${values.n5}${values.n6}`,
            userId: user.userId
        })
		if (response?.data?.success) {
            setShowDetailModal(!showDetailModal)
            setEnableTFA(true)
            window.history.go(0)
			customNotification(t(""), t("VerifyTFASuccess"), "success")
		} else {
			customNotification(t("Error"), t("VerifyTFAError"), "danger")
		}
	}

    const validateTFA = async (values) => {
        const userEmployee = JSON.parse(localStorage.getItem("userEmployee"))
        const userInfoTFA = JSON.parse(localStorage.getItem("userInfoTFA"))
		const response = await validateOTP({
            token: `${values.n1}${values.n2}${values.n3}${values.n4}${values.n5}${values.n6}`,
            userId: userEmployee ? userEmployee.userId : userInfoTFA.userId
        })
      
		if (response?.data?.success) {
            localStorage.setItem("userInfo", JSON.stringify(userInfoTFA))	
            localStorage.removeItem('userInfoTFA')
            window.history.go(0)
		} else {
			customNotification(t("Error"), t("VerifyTFAError"), "danger")
		}
	}

    const disableTFA = async () => {
        setEnableTFA(null)
        await disableOTP({
            contactId: user.userId
        })
        window.history.go(0)
        customNotification(t(""), t("DisableTFASuccess"), "success")
	}

    const handleChangeToken = (e) => {
        setToken({
            ...token,
            [e.target.name]: e.target.value
        })
    }

	return {
		enabledTFA,
        verifyTFA,
        secret,
        handleChangeToken,
        showDetailModal, 
        setShowDetailModal,
        enableTFA,
        validateTFA,
        disableTFA
	}
}

export default useTwoFactorAuth
