
export const USER_TYPES = {
    IBO: 1,
    EMPLOYEE: 2,
    IBO_CLIENT: 9
}

export const DAYS = [
    { id: 1, name: "01" },
    { id: 2, name: "02" },
    { id: 3, name: "03" },
    { id: 4, name: "04" },
    { id: 5, name: "05" },
    { id: 6, name: "06" },
    { id: 7, name: "07" },
    { id: 8, name: "08" },
    { id: 9, name: "09" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
    { id: 13, name: "13" },
    { id: 14, name: "14" },
    { id: 15, name: "15" },
    { id: 16, name: "16" },
    { id: 17, name: "17" },
    { id: 18, name: "18" },
    { id: 19, name: "19" },
    { id: 20, name: "20" },
    { id: 21, name: "21" },
    { id: 22, name: "22" },
    { id: 23, name: "23" },
    { id: 24, name: "24" },
    { id: 25, name: "25" },
    { id: 26, name: "26" },
    { id: 27, name: "27" },
    { id: 28, name: "28" },
    { id: 29, name: "29" },
    { id: 30, name: "30" },
    { id: 31, name: "31" }
]

export const MONTHS = [
    { id: 1, name: "01" },
    { id: 2, name: "02" },
    { id: 3, name: "03" },
    { id: 4, name: "04" },
    { id: 5, name: "05" },
    { id: 6, name: "06" },
    { id: 7, name: "07" },
    { id: 8, name: "08" },
    { id: 9, name: "09" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" }
]

export const gender = (t) => {
    return [
        { id: 1, name: t("Female") },
        { id: 2, name: t("Male") },
        { id: 3, name: t("Other") }
    ]
}

export const MERCHANTS = {
    BLOCKPAY: 9,
    EWALLET: 4,
    MASSPAY: 15
}

export const INVESTMENT_TYPES = {
    SPORTS: 1,
    FOREX: 2,
    CRIPTOCURRENCY: 3,
    MIX: 4,
    PROFITABILITY_SPORTS: 5,
    PROFITABILITY_FOREX: 6,
    PROFITABILITY_CRIPTOCURRENCY: 7,
    PROFITABILITY_MIX: 8,
    PROFITABILITY_WITHDRAW: 9,
    PROFITABILITY_REINVEST: 10
}

export const ACCOUNT_TYPES = {
    MAIN: 1,
    POSITIVE_BALANCE: 2
}