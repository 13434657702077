import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toTime } from '../../../utility/Utils'

const RefreshSession = ({ open, remaining, toggleModal, handleStillHere, t }) => {


  return (
    <div className={'theme-{item.modalColor}'}>
      <Modal
        isOpen={open}
        toggle={() => toggleModal()}
        className='modal-dialog-centered'
        modalClassName={"modal-primary"}
      >
        <ModalHeader>{t("Inactivity")}</ModalHeader>
        <ModalBody>
          <p>{t('ContinueInSessionInfo').replace('{0}', toTime(remaining))}</p>
          <p>{t('ContinueInSession')}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleStillHere}>
            {t("Yes")}
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )

}

export default RefreshSession