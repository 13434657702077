import { Store } from 'react-notifications-component'

const Notification = () => {

    const customNotification = (title, message, type) => {
        Store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__bounceIn"],
            animationOut: ["animate__animated", "animate__bounceOut"],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
        })
    }

    return {
        customNotification
    }

}

export default Notification