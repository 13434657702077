/*eslint-disable*/
import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import RefreshSession from "../@core/components/refresh-sesion";
import { useTranslation } from "react-i18next";
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";

export const IdleContext = createContext();

const timeout = 240_000;
const promptBeforeIdle = 60_000;

export const IdleProvider = ({ children }) => {
	// ** States
	const [status, setStatus] = useState("Active");
	const [open, setOpen] = useState(false);
	const [remaining, setRemaining] = useState(0);

	// ** Hooks
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate()

	const onIdle = () => {
		setStatus("Idle");
		setOpen(false);
		localStorage.removeItem("userInfo")
		localStorage.removeItem("userInfoTFA")
		localStorage.removeItem("tokenVT")
		localStorage.removeItem("userEmployee")
		localStorage.removeItem("tokenBP")
		navigate('/login')
	};

	const onActive = () => {
		setStatus("Active");
		setOpen(false);
	};

	const onPrompt = () => {
		setStatus("Prompted");
    
		if (location.pathname !== '/login') {
      		setOpen(true);
		}
	};

	const { getRemainingTime, activate } = useIdleTimer({
		onIdle,
		onActive,
		events: ["mousedown", "keypress"],
		onPrompt,
		timeout,
		promptBeforeIdle,
		throttle: 500,
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 500);

		document.addEventListener('click', () => {
		  refreshToken()
		})

		return () => {
			clearInterval(interval);
		};
	}, []);

	const handleClose = () => {
		onIdle();
	};

	const handleStillHere = async () => {
		// const tokenBP = await getTokenBP()
		// const tokenVT = await getToken()
		// if (tokenBP) {
		//   localStorage.setItem('tokenBP', tokenBP[0])
		// }
		// if (tokenVT) {
		//   localStorage.setItem('tokenVT', tokenVT.token)
		// }
		activate();
	};

  const refreshToken = () => {
    if (location.pathname === '/login') {
      return
    }
    const token = localStorage.getItem('tokenBP')

    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now() && status === 'Active') {
        handleStillHere()
      }
    }
  }

	return (
		<IdleContext.Provider
			value={{
				status,
				remaining,
				open,
				handleStillHere,
			}}
		>
			<div>
				{children}
				<RefreshSession 
          open={open} 
          toggleModal={handleClose} 
          remaining={remaining} 
          handleStillHere={handleStillHere} 
		  refreshToken={refreshToken}
          t={t} 
        />
			</div>
		</IdleContext.Provider>
	);
};

export const idleContext = () => {
	return useContext(IdleContext);
};
