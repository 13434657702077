// ** React Imports
import { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors"

// ** Toast
import { Toaster } from "react-hot-toast"

// ** i18n
import "./configs/i18n"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Notifications
import {  ReactNotifications  } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import ThemeConfig from "./configs/themeConfig"

import { UserProvider } from "./context/user"
import { GenericProvider } from "./context/generic"
import { IdleProvider } from "./context/idle"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
    <UserProvider>
      <GenericProvider>
        <BrowserRouter>
          <IdleProvider>
              <ReactNotifications />  
              <Provider store={store}>
                <Suspense fallback={<Spinner />}>
                  <ThemeContext>
                    <LazyApp />
                    <Toaster
                      position={ThemeConfig().themeConfig.layout.toastPosition}
                      toastOptions={{ className: "react-hot-toast" }}
                    />
                  </ThemeContext>
                </Suspense>
              </Provider>
          </IdleProvider>
        </BrowserRouter>
      </GenericProvider>
    </UserProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
