// You can customize the template with the help of this file

//Template config options

const ThemeConfig = () => {

  const skin = localStorage.getItem('skin')

  const themeConfig = {
    app: {
      appName: "",
      appLogoImage: `https://cdn1.visiontravel.net/Images/blockpay/${skin === '"dark"' ? "BlockPay-white.png" : "BlockPay-dark.png"}`
    },
    layout: {
      isRTL: false,
      skin: "light", // light, dark, bordered, semi-dark
      type: "vertical", // vertical, horizontal
      contentWidth: "boxed", // full, boxed
      menu: {
        isHidden: false,
        isCollapsed: false
      },
      navbar: {
        // ? For horizontal menu, navbar type will work for navMenu type
        type: "floating", // static , sticky , floating, hidden
        backgroundColor: "white" // BS color options [primary, success, etc]
      },
      footer: {
        type: "static" // static, sticky, hidden
      },
      customizer: false,
      scrollTop: true, // Enable scroll to top button
      toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
    }
  }

  return {
    themeConfig
  }
}


export default ThemeConfig
