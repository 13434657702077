import { API } from "../instances"

export const generateOTP = async (payload) => {
  const result = await API()
    .post("blockpay/twoFactorAuth/generateOTP", payload)
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result
  }
  return []
}


export const verifyOTP = async (payload) => {
  const result = await API()
    .post("blockpay/twoFactorAuth/verifyOTP", payload)
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result
  }
  return []
}

export const validateOTP = async (payload) => {
  const result = await API()
    .post("blockpay/twoFactorAuth/validateOTP", payload)
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result
  }
  return []
}

export const disableOTP = async (payload) => {
  const result = await API()
    .post("blockpay/twoFactorAuth/disableOTP", payload)
    .catch((error) => {
      return error.response ? error.response : error
    })

  if (result.status === 200) {
    return result
  }
  return []
}